<template>
    <List
      :api="list.api"
      :header="list.headers"
      :items="list.col"
      :item-key="list.key"
      disableGroupActions
      disableCheckbox
    >
      <template v-slot:media="{ item }">
        {{ item.media?.extension }}
      </template>

      <template v-slot:categorie="{ item }">
        {{ item.categorie?.nom }}
      </template>

      <template v-slot:actions="{ item }">
        <ToolDropdown>
          <MenuItem
            v-if="helperService.userHasPermission('essaifichier_show,fichier_show')"
            :item-data="{
              label: 'Consulter',
              iconName: 'visibility'
            }"
            @click="openModal('viewFile', item)"
          />
          <MenuItem
            v-if="helperService.userHasPermission('essaifichier_show,fichier_show')"
            :item-data="{
              label: 'Télécharger',
              iconName: 'download'
            }"
            @click="downloadFile(item)"
          />
        </ToolDropdown>
      </template>
    </List>

    <!-- Modals -->

    <!-- View/Download file -->
    <Modal
      title="Consulter / Télécharger le fichier"
      :active="modal.viewFile"
      :data="modalData"
      @modal-close="modal.viewFile = false"
    >
      <template
        v-slot:modal-body="{ data }"
      >
        <ImageToken
          v-if="['jpg', 'jpeg', 'png', 'svg'].includes(data?.media?.extension)"
          :image="data?.media"
          :alt="data?.nom"
          size="full"
        />
        <p v-else>Aucun aperçu n'est disponible pour ce type de fichier.</p>
      </template>
      <template v-slot:modal-footer="{ data }">
        <Btn text="Annuler" @click="modal.viewFile = false" />
        <Btn
          text="Télécharger"
          @click="downloadFile(data)"
          icon="download"
          color="primary"
        />
      </template>
    </Modal>

  </template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import ImageToken from '@/components/base/ImageToken.vue'

export default {
  name: 'EssaiFilesView',

  components: {
    ImageToken,
    MenuItem,
    ToolDropdown,
    Modal,
    List,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      search: {
        attributs: ['titre'],
        filters: {},
      },

      list: {
        api: {
          endpoint: `essai/${this.$route.params.id}/fichier`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom du document' },
          { label: 'Type' },
          { label: 'Catégorie' },
        ],
        col: ['description', 'media', 'categorie'],
        key: 'id',
      },

      modalData: {},
      modal: {
        viewFile: false,
      },
      essai: {},
      componentKey: 0,
    }
  },

  emits: ['updateEssais'],

  mounted() {
    this.getEssai()
  },

  methods: {
    setMedia(event) {
      this.file = event
    },

    openModal(modal, data = {}) {
      const handleData = { ...data }
      this.modalData = handleData
      this.modal[modal] = true
    },

    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.essai = response.data
      })
    },

    downloadFile(item) {
      this.fileService.downloadFile(`utilitaire/media/${item.media.jeton}.telecharger`, item.media.nom)
    },
  },
}
</script>

  <style scoped>
  </style>
